import React, { useState, useEffect } from 'react';
import posthog, { PostHog as PostHogType } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

interface PostHogProps {
  children: React.ReactNode;
}

/**********************************************************************
 * * VERIFY ANALYTICS CONSENT
 * --------------------------------------------------------------------
 * This function checks if the user has given consent to use analytics.
 * If the user is on localhost, analytics is enabled. If the user has
 * given consent, analytics is enabled. Otherwise, analytics is
 * disabled.
 * ******************************************************************/
const isBrowser = typeof window !== 'undefined';

const isAnalyticsEnabled = () => {
  // * Return false or handle appropriately for server-side rendering (SSR)
  if (!isBrowser) {
    return false;
  }

  // * Block analytics if the URL contains the query parameter "sandbox"
  const urlParams = new URLSearchParams(window.location.search);
  const sandbox = urlParams.get('sandbox');
  if (sandbox !== null) {
    return false;
  }

  // * UNCOMMENT FOR LOCAL HOST TESTING WHEN NECESSARY
  // Enable analytics if the user is on localhost
  if (window.location.hostname === 'localhost') {
    return true;
  }

  // * COOKIE CONSENT CHECK
  // Check if the user has explicitly given consent to use analytics and return true
  const consentCookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('cookieyes-consent='))
    ?.split('=')[1];

  if (consentCookie && consentCookie.includes('analytics:yes')) {
    return true;
  }

  // * DEFAULT RETURN FALSE
  return false;
};

/**********************************************************************
 * * INITIALIZE POSTHOG
 * --------------------------------------------------------------------
 * This function initializes PostHog with the public key and host. If
 * analytics is enabled, the real PostHog client is initialized. If
 * analytics is not enabled, a no-op client is initialized.
 * ******************************************************************/

// Check if analytics is enabled
// const enable = isAnalyticsEnabled();

const initializePostHog = (enabled:boolean) => {
  posthog.init(process.env.GATSBY_PUBLIC_POSTHOG_KEY as string, {
    api_host: process.env.GATSBY_PUBLIC_POSTHOG_HOST as string,
    // USE COOKIE PERSISTENCE IF ANALYTICS IS ENABLED OTHERWISE USE MEMORY PERSISTENCE
    persistence: enabled ? 'cookie' : 'memory',
  });

  return posthog;  
};

/**********************************************************************
 * * POSTHOG COMPONENT
 * --------------------------------------------------------------------
 * This component initializes PostHog and wraps the children in a
 * PostHogProvider. The PostHogProvider is a context provider that
 * allows the children to access the PostHog client.
 * 
 * The component also listens for changes in analytics consent and
 * updates the PostHog client accordingly.
 * ******************************************************************/

const PostHog: React.FC<PostHogProps> = ({ children }) => {

  /************************************************************
   * * STATE FOR ANALYTICS ENABLED AND POSTHOG CLIENT
   * ----------------------------------------------------------
   * State values will be changed based on cookie yes events
   * fired by the cookie consent plugin which are listened to
   * by the useEffect hook.
   * **********************************************************/
  const [enabled, setEnabled] = useState(isAnalyticsEnabled());
  const [client, setClient] = useState(() => initializePostHog(enabled));

  /************************************************************
   * * USE EFFECT FOR LISTENING TO CONSENT CHANGES
   * ----------------------------------------------------------
   * This useEffect listens for changes in analytics consent and
   * updates the PostHog client accordingly.
   * **********************************************************/
  useEffect(() => {

    console.debug('PostHog: Listening for consent changes');
    
    const checkConsentChange = () => {
      const currentEnabled = isAnalyticsEnabled();
      if (currentEnabled !== enabled) {
        setEnabled(currentEnabled);
        setClient(initializePostHog(currentEnabled));
      }
    };
    
    // Immediately check on mount and then listen for changes
    checkConsentChange();
    
    /************************************************************
     * * COOKIE YES EVENT LISTENER
     * ----------------------------------------------------------
     * This event listener listens for changes in analytics consent.
     * **********************************************************/
    window.addEventListener('cookieyes-consent-update', checkConsentChange); // Adjust this event name based on how cookie updates are detected
    return () => {
      window.removeEventListener('cookieyes-consent-update', checkConsentChange);
    };
  }, []);

  
  return <PostHogProvider client={client}>{children}</PostHogProvider>;
};

export default PostHog;
