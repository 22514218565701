import * as React from 'react';
import { current, castDraft, Immutable, Draft } from 'immer';
import { useImmerReducer } from 'use-immer';
import { SessionStorage as storage } from '../storage/Storage';

import { graphql, useStaticQuery } from 'gatsby';
import {
  DiscountCodeType,
  GetProductsAndPricesQuery,
  ProductList,
  ValidateDiscountCodeQuery,
  ValidateDiscountCodeResponse,
  ValidateDiscountCodeResponseList,
} from '../../graphql/elomapi';
import { GraphQLOptions, GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import { getProductsAndPrices, validateDiscountCode } from '../../graphql/queries';
import { API } from 'aws-amplify';

import { ThemeProvider } from 'styled-components';
import { BaseStyles, TypographyStyles } from '../../theme/GlobalStyles';
import { base } from '../../theme/Theme';
import { Product, SubscriptionModel } from '../product/Product.types';
import { Cart, Coupon, LineItem, Promotion, UTMData } from './EloStore.types';

type Mode = 'stub' | 'content-loaded' | 'ready';

export type State = Immutable<{
  key: 'elo-health-store';
  mode: Mode;
  modified: Date;
  promotions: Promotion[];
  products: {
    [slug: string]: Product;
  };
  layout: {
    theme: 'dark' | 'light';
    cartWidged: {
      shown: boolean;
    };
    loader: {
      shown: boolean;
    };
  };
  cart: Cart;
  analytics: {
    utmData?: UTMData;
  };
}>;

const initialState: State = {
  key: 'elo-health-store',
  mode: 'stub',
  modified: new Date(),
  promotions: [],
  products: {},
  layout: {
    theme: 'light',
    cartWidged: {
      shown: false,
    },
    loader: {
      shown: false,
    },
  },
  cart: {
    loaded: false,
    saved: null,
    owner: null,
    items: {},
    discount: { mode: 'stub' },
  },
  analytics: {},
};

type Action =
  | { type: 'store-ready' }
  | { type: 'cart-clear' }
  | { type: 'cart-saved' }
  | { type: 'cart-restore'; payload: { cart: Cart } }
  | { type: 'cart-add-or-edit-product'; payload: LineItem }
  | {
      type: 'cart-add-or-edit-discount';
      payload: { code: string; coupon: Coupon | null; isValid: boolean };
    }
  | { type: 'cart-remove-product'; payload: { productSlug: string } }
  | { type: 'layout-cart-widget-set-shown'; payload: { shown: boolean } }
  | { type: 'layout-loader-set-shown'; payload: { shown: boolean } }
  | { type: 'layout-set-theme'; payload: { theme: 'light' | 'dark' } }
  | { type: 'products-populate'; payload: { contentfulData: Queries.ProductsQuery } }
  | {
      type: 'products-apply-stripe-data';
      payload: { productData: ProductList | null; promotions?: ValidateDiscountCodeResponse[] };
    }
  | {
      type: 'analytics-store-utm-data';
      payload: {
        utm_source?: string;
        utm_medium?: string;
        utm_campaign?: string;
        utm_content?: string;
        utm_term?: string;
      };
    };

const reducer = (draft: Draft<State>, action: Action) => {
  console.debug(`EloStore: [${action.type}]`, action, current(draft));

  switch (action.type) {
    case 'store-ready': {
      draft.mode = 'ready';
      if(!draft.cart.owner) {
        draft.cart.owner = crypto ? crypto.randomUUID() : null;
      }

      break;
    }
    case 'cart-saved': {
      draft.cart.saved = new Date();
      break;
    }
    case 'cart-clear': {
      draft.cart = {
        ...castDraft(initialState.cart),
        owner: draft.cart.owner,
      };

      break;
    }
    case 'cart-restore': {
      draft.cart = castDraft({ ...draft.cart, ...action.payload.cart });
      if(!draft.cart.owner) {
        draft.cart.owner = crypto ? crypto.randomUUID() : null;
      }

      break;
    }
    case 'cart-add-or-edit-product': {
      const items: { [id: string]: LineItem } = {};
      items[action.payload.productSlug] = {
        productSlug: action.payload.productSlug,
        subscriptionSlug: action.payload.subscriptionSlug,
        variantSlugs: action.payload.variantSlugs,
      };

      draft.cart.items = items;

      break;
    }
    case 'cart-add-or-edit-discount': {
      draft.cart.discount.code = action.payload.code;
      draft.cart.discount.mode = action.payload.isValid ? 'valid' : 'invalid';

      if (action.payload.isValid && action.payload.coupon) {
        draft.cart.discount.coupon = castDraft(action.payload.coupon);
      }

      break;
    }
    case 'cart-remove-product': {
      delete draft.cart.items[action.payload.productSlug];
      break;
    }
    case 'layout-cart-widget-set-shown': {
      draft.layout.cartWidged.shown = castDraft(action.payload.shown);
      break;
    }
    case 'layout-loader-set-shown': {
      draft.layout.loader.shown = castDraft(action.payload.shown);
      break;
    }
    case 'layout-set-theme': {
      draft.layout.theme = action.payload.theme;
      break;
    }
    case 'products-populate': {
      const productModels = action.payload.contentfulData.allContentfulProduct.nodes;

      const products = productModels.map((productModel: any) => {
        const product: Product = {
          ...(productModel as Product),
          subscriptionModels: {},
        };

        if (productModel.subscriptionModels) {
          Object.values(productModel.subscriptionModels).forEach((price: any) => {
            if (price && price.slug) {
              product.subscriptionModels[price.slug] = {
                ...(price as SubscriptionModel),
                source: 'contenful',
              };
            }
          });
        }

        return product;
      });

      products.forEach((p) => (draft.products[p.slug] = castDraft(p)));
      draft.mode = 'content-loaded';

      break;
    }
    case 'products-apply-stripe-data': {
      if (action.payload.promotions) {
        draft.promotions = action.payload.promotions?.map((p) => ({
          id: p.id,
          valid: p.valid,
          code: p.code,
          ...(p.coupon
            ? {
                coupon: {
                  id: p.coupon.id,
                  name: p.coupon.name ?? null,
                  appliesTo: p.coupon.appliesTo
                    ? p.coupon.appliesTo.filter((s): s is string => !!s)
                    : [],
                  amountOff: p.coupon.amountOff ?? null,
                  percentOff: p.coupon.percentOff ?? null,
                },
              }
            : {}),
        }));
      }

      const stripeProducts = action.payload.productData;
      stripeProducts?.products?.forEach((stripeProduct) => {
        const product = Object.values(draft.products).find(
          (p) => p.stripeProductId === stripeProduct?.id
        );

        if (product && product.slug) {
          stripeProduct?.pricing?.forEach((stripePrice) => {
            const subscriptionModel = Object.values(
              draft.products[product.slug].subscriptionModels
            ).find((v) => v.stripePriceId === stripePrice?.id);
            if (subscriptionModel && subscriptionModel.slug) {
              draft.products[product.slug].subscriptionModels[subscriptionModel.slug] = {
                ...subscriptionModel,
                source: 'stripe',
                pricing: [
                  ...subscriptionModel.pricing.map((pricing) => {
                    if (pricing.stripePriceId === stripePrice?.id) {
                      return {
                        ...pricing,
                        primary: true,
                        price: stripePrice?.unitAmount ?? pricing.price,
                        recurrence: stripePrice?.recurring?.interval ?? undefined,                        
                        recurrenceInterval: stripePrice?.recurring?.intervalCount ?? undefined,                        
                      };
                    }

                    return pricing;
                  }),
                ],
              };
            }
          });
        }
      });

      break;
    }
    case 'analytics-store-utm-data': {
      draft.analytics.utmData = action.payload;
      break;
    }
  }
};

interface ContextInteface {
  store: State;
  dispatch: React.Dispatch<Action>;
  saveCartContents: () => Promise<void>;
}

const Context = React.createContext<ContextInteface>({
  store: initialState,
  dispatch: (action) =>
    console.error('Dispatched action outside of an ShoppingCart Context provider', action),
  saveCartContents: async () => {
    return;
  },
});

type Props = {
  children?: React.ReactNode;
  dummyMode?: boolean;
};

async function getProductsAndPricing(
  productIds: string[],
  priceIds: string[]
): Promise<ProductList | null> {
  const query: GraphQLOptions = {
    query: getProductsAndPrices,
    authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    variables: {
      productIds: productIds,
      priceIds: priceIds,
    },
  };

  try {
    const result = (await API.graphql(query)) as GraphQLResult<GetProductsAndPricesQuery>;
    return result.data?.getProductsAndPrices ?? null;
  } catch (error) {
    console.error('GetProductsAndPricesQuery failed', error);
  }

  return null;
}

async function getCoupons(couponIds: string[]): Promise<ValidateDiscountCodeResponseList | null> {
  const query: GraphQLOptions = {
    query: validateDiscountCode,
    authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    variables: {
      code: couponIds,
      type: DiscountCodeType.PROMOTION_CODE_ID,
    },
  };

  try {
    const result = (await API.graphql(query)) as GraphQLResult<ValidateDiscountCodeQuery>;
    return result.data?.validateDiscountCode ?? null;
  } catch (error) {
    console.error('validateDiscountCode failed', error);
  }

  return null;
}

function Provider({ children, dummyMode = false }: Props): JSX.Element {
  const [store, dispatch] = useImmerReducer(reducer, initialState);

  const saveCartContents = React.useCallback(async () => {
    await storage.setItem(store.key, JSON.stringify(store.cart));
  }, [store, storage]);

  const productsModels = dummyMode ? null : useStaticQuery<Queries.ProductsQuery>(productsQuery);

  React.useEffect(() => {
    switch (store.mode) {
      case 'stub': {
        if (productsModels) {
          console.debug('Adding products to context.', productsModels);
          dispatch({ type: 'products-populate', payload: { contentfulData: productsModels } });
        }

        (async () => {
          const data = await storage.getItem(store.key);
          let cart;
          if (data) {
            console.debug('Restoring previously stored cart.', data);
            cart = JSON.parse(data);
          } else {
            console.debug('Nothing to restore / No previous shopping cart exists.');
          }
          dispatch({
            type: 'cart-restore',
            payload: { cart: { ...cart, loaded: true } as Cart },
          });
        })();

        break;
      }
      case 'content-loaded': {
        console.debug('Initial render done, loading product data from Stripe.');
        (async () => {
          const productIds = Object.values(store.products).map((p) => p.stripeProductId);
          const coupons = Object.values(store.products).map((p) => p.coupon?.stripeCouponId);
          const priceIds = Object.values(store.products)
            .flatMap((p) => Object.values(p.subscriptionModels))
            .map((subscriptionModel) => subscriptionModel.stripePriceId)
            .filter((id): id is string => !!id);

          const stripeProducts = await getProductsAndPricing(productIds, priceIds);
          const stripeDiscounts = await getCoupons(coupons);

          const promotions = stripeDiscounts?.results?.filter(
            (d): d is ValidateDiscountCodeResponse => !!d
          );

          dispatch({
            type: 'products-apply-stripe-data',
            payload: { productData: stripeProducts, promotions },
          });
          dispatch({ type: 'store-ready' });
        })();

        break;
      }
    }
  }, [store.mode]);

  React.useEffect(() => {
    saveCartContents().then(() => dispatch({ type: 'cart-saved' }));
  }, [store.cart.items, store.cart.discount]);
  return (
    <Context.Provider value={{ store, dispatch, saveCartContents }}>
      <ThemeProvider theme={{ ...base, colorScheme: store.layout.theme }}>
        <BaseStyles />
        <TypographyStyles />
        {children}
      </ThemeProvider>
    </Context.Provider>
  );
}

export const productsQuery = graphql`
  query Products {
    allContentfulProduct {
      nodes {
        ...ProductFragment
      }
    }
  }
`;

export { Provider, Context };
