import * as React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { Provider as EloStoreProvider } from './src/features/store/EloStore.Provider';
import Posthog from './src/Posthog';

// resetting scrolls
export const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] = () => {
  window.scrollTo(0, 0);

  // setTimeout(() => {
  //   const nav = document.getElementById('header');
  //   nav?.classList.remove('scrolling');
  // }, 500);
};

export const onPreRouteUpdate: GatsbyBrowser['onPreRouteUpdate'] = () => {
  document.body.scrollTop = 0;
};

// Strip query strings from canonical urls
export const onRouteUpdate = props => {
  const { location } = props;
  const elem = document.querySelector(`link[rel='canonical']`);
  if (elem) {
    const hasHref = elem.getAttribute(`href`) ?? false;
    const protocol = elem.getAttribute(`data-baseProtocol`);
    const host = elem.getAttribute(`data-baseHost`);
    if (hasHref && protocol && host) {
      // `${protocol}//${host}${location.pathname}${location.search}${location.hash}`
      elem.setAttribute(`href`, `${protocol}//${host}${location.pathname}${location.hash}`);
    }
  }
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <EloStoreProvider>
    <Posthog>{element}</Posthog>
  </EloStoreProvider>
);
