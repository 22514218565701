/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./elomapi";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getStatus = /* GraphQL */ `query GetStatus {
  getStatus {
    service
    status
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetStatusQueryVariables, APITypes.GetStatusQuery>;
export const getCheckout = /* GraphQL */ `query GetCheckout($sessionId: ID!) {
  getCheckout(sessionId: $sessionId) {
    checkoutUrl
    expiresAt
    orderId
    name
    email
    shippingAddress {
      name
      company
      street
      street2
      city
      state
      zip
      country
      __typename
    }
    billingAddress {
      name
      company
      street
      street2
      city
      state
      zip
      country
      __typename
    }
    paymentMethodType
    paymentMethodDescription
    lineItems {
      type
      title
      id
      priceId
      sku
      imageUrl
      description
      quantity
      unitPrice
      trialUnitPrice
      currency
      __typename
    }
    variants
    totalPrice {
      currency
      discount
      shipping
      tax
      total
      __typename
    }
    appStoreDownloadUrl
    fail {
      errorCode
      errorMessage
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCheckoutQueryVariables,
  APITypes.GetCheckoutQuery
>;
export const getProductsAndPrices = /* GraphQL */ `query GetProductsAndPrices($productIds: [String], $priceIds: [String]) {
  getProductsAndPrices(productIds: $productIds, priceIds: $priceIds) {
    products {
      id
      slug
      name
      category
      features
      pricing {
        id
        type
        currency
        billingScheme
        unitAmount
        recurring {
          aggregateUsage
          interval
          intervalCount
          usageType
          __typename
        }
        __typename
      }
      shortDescription
      longDescription
      serving {
        unit
        quantity
        __typename
      }
      servingCount
      products {
        id
        slug
        name
        category
        features
        pricing {
          id
          type
          currency
          billingScheme
          unitAmount
          __typename
        }
        shortDescription
        longDescription
        serving {
          unit
          quantity
          __typename
        }
        servingCount
        products {
          id
          slug
          name
          category
          features
          shortDescription
          longDescription
          servingCount
          __typename
        }
        ingredients {
          id
          slug
          name
          category
          shortDescription
          longDescription
          __typename
        }
        assessments {
          type
          name
          slug
          __typename
        }
        assets {
          createdAt
          contentType
          title
          description
          tags
          assetKey
          assetUrl
          thumbnailKey
          thumbnailUrl
          cmsKey
          cmsId
          __typename
        }
        __typename
      }
      ingredients {
        id
        slug
        name
        category
        shortDescription
        longDescription
        dose {
          quantity
          unit
          __typename
        }
        assessments {
          type
          name
          slug
          __typename
        }
        assets {
          createdAt
          contentType
          title
          description
          tags
          assetKey
          assetUrl
          thumbnailKey
          thumbnailUrl
          cmsKey
          cmsId
          __typename
        }
        __typename
      }
      assessments {
        type
        name
        slug
        __typename
      }
      assets {
        createdAt
        contentType
        title
        description
        tags
        assetKey
        assetUrl
        thumbnailKey
        thumbnailUrl
        cmsKey
        cmsId
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductsAndPricesQueryVariables,
  APITypes.GetProductsAndPricesQuery
>;
export const validateDiscountCode = /* GraphQL */ `query ValidateDiscountCode($type: DiscountCodeType!, $code: [String]) {
  validateDiscountCode(type: $type, code: $code) {
    results {
      id
      code
      valid
      reason
      coupon {
        id
        name
        appliesTo
        amountOff
        percentOff
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ValidateDiscountCodeQueryVariables,
  APITypes.ValidateDiscountCodeQuery
>;
export const getPreliminaryPlan = /* GraphQL */ `query GetPreliminaryPlan($id: ID!) {
  getPreliminaryPlan(id: $id) {
    ... on ClientErrorPlanNotFound {
      message
    }
    ... on ClientErrorPlanExpired {
      message
    }
    ... on ExternalErrorS3InvalidData {
      message
    }
    ... on PreliminaryPlan {
      id
      status
      createdAt
      expiresAt
      member {
        anonymousId
        firstName
        lastName
        name
        email
        affiliations
        age
        sex
        diets
        sports
        goals
        targeting {
          utmCampaign
          utmSource
          utmMedium
          utmContent
          utmTerm
          __typename
        }
        __typename
      }
      products {
        id
        slug
        name
        category
        features
        pricing {
          id
          type
          currency
          billingScheme
          unitAmount
          recurring {
            aggregateUsage
            interval
            intervalCount
            usageType
            __typename
          }
          __typename
        }
        shortDescription
        longDescription
        serving {
          unit
          quantity
          __typename
        }
        servingCount
        products {
          id
          slug
          name
          category
          features
          pricing {
            id
            type
            currency
            billingScheme
            unitAmount
            __typename
          }
          shortDescription
          longDescription
          serving {
            unit
            quantity
            __typename
          }
          servingCount
          products {
            id
            slug
            name
            category
            features
            shortDescription
            longDescription
            servingCount
            __typename
          }
          ingredients {
            id
            slug
            name
            category
            shortDescription
            longDescription
            __typename
          }
          assessments {
            type
            name
            slug
            __typename
          }
          assets {
            createdAt
            contentType
            title
            description
            tags
            assetKey
            assetUrl
            thumbnailKey
            thumbnailUrl
            cmsKey
            cmsId
            __typename
          }
          __typename
        }
        ingredients {
          id
          slug
          name
          category
          shortDescription
          longDescription
          dose {
            quantity
            unit
            __typename
          }
          assessments {
            type
            name
            slug
            __typename
          }
          assets {
            createdAt
            contentType
            title
            description
            tags
            assetKey
            assetUrl
            thumbnailKey
            thumbnailUrl
            cmsKey
            cmsId
            __typename
          }
          __typename
        }
        assessments {
          type
          name
          slug
          __typename
        }
        assets {
          createdAt
          contentType
          title
          description
          tags
          assetKey
          assetUrl
          thumbnailKey
          thumbnailUrl
          cmsKey
          cmsId
          __typename
        }
        __typename
      }
      error
      errorMessages
    }
  }
}
` as GeneratedQuery<
  APITypes.GetPreliminaryPlanQueryVariables,
  APITypes.GetPreliminaryPlanQuery
>;
