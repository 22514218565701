exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-answers-tsx": () => import("./../../../src/pages/answers.tsx" /* webpackChunkName: "component---src-pages-answers-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-assessment-smart-gummies-index-tsx": () => import("./../../../src/pages/assessment/smart-gummies/index.tsx" /* webpackChunkName: "component---src-pages-assessment-smart-gummies-index-tsx" */),
  "component---src-pages-biomarkers-tsx": () => import("./../../../src/pages/biomarkers.tsx" /* webpackChunkName: "component---src-pages-biomarkers-tsx" */),
  "component---src-pages-cart-account-index-tsx": () => import("./../../../src/pages/cart/account/index.tsx" /* webpackChunkName: "component---src-pages-cart-account-index-tsx" */),
  "component---src-pages-cart-assessment-summary-index-tsx": () => import("./../../../src/pages/cart/assessment-summary/index.tsx" /* webpackChunkName: "component---src-pages-cart-assessment-summary-index-tsx" */),
  "component---src-pages-cart-formula-index-tsx": () => import("./../../../src/pages/cart/formula/index.tsx" /* webpackChunkName: "component---src-pages-cart-formula-index-tsx" */),
  "component---src-pages-cart-index-tsx": () => import("./../../../src/pages/cart/index.tsx" /* webpackChunkName: "component---src-pages-cart-index-tsx" */),
  "component---src-pages-cart-preferences-1-step-index-tsx": () => import("./../../../src/pages/cart/preferences/1step/index.tsx" /* webpackChunkName: "component---src-pages-cart-preferences-1-step-index-tsx" */),
  "component---src-pages-cart-preferences-index-tsx": () => import("./../../../src/pages/cart/preferences/index.tsx" /* webpackChunkName: "component---src-pages-cart-preferences-index-tsx" */),
  "component---src-pages-cart-qualification-index-tsx": () => import("./../../../src/pages/cart/qualification/index.tsx" /* webpackChunkName: "component---src-pages-cart-qualification-index-tsx" */),
  "component---src-pages-cart-summary-index-tsx": () => import("./../../../src/pages/cart/summary/index.tsx" /* webpackChunkName: "component---src-pages-cart-summary-index-tsx" */),
  "component---src-pages-checkout-success-tsx": () => import("./../../../src/pages/checkout-success.tsx" /* webpackChunkName: "component---src-pages-checkout-success-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-smart-gummies-tsx": () => import("./../../../src/pages/products/smart-gummies.tsx" /* webpackChunkName: "component---src-pages-products-smart-gummies-tsx" */),
  "component---src-pages-products-smart-protein-tsx": () => import("./../../../src/pages/products/smart-protein.tsx" /* webpackChunkName: "component---src-pages-products-smart-protein-tsx" */),
  "component---src-pages-products-smart-supplements-tsx": () => import("./../../../src/pages/products/smart-supplements.tsx" /* webpackChunkName: "component---src-pages-products-smart-supplements-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-promo-smart-protein-tsx": () => import("./../../../src/pages/promo/smart-protein.tsx" /* webpackChunkName: "component---src-pages-promo-smart-protein-tsx" */),
  "component---src-pages-qualification-tsx": () => import("./../../../src/pages/qualification.tsx" /* webpackChunkName: "component---src-pages-qualification-tsx" */),
  "component---src-pages-quiz-smart-recovery-index-tsx": () => import("./../../../src/pages/quiz/smart-recovery/index.tsx" /* webpackChunkName: "component---src-pages-quiz-smart-recovery-index-tsx" */),
  "component---src-pages-ref-tsx": () => import("./../../../src/pages/ref.tsx" /* webpackChunkName: "component---src-pages-ref-tsx" */),
  "component---src-templates-answer-tsx": () => import("./../../../src/templates/answer.tsx" /* webpackChunkName: "component---src-templates-answer-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-biomarker-score-template-tsx": () => import("./../../../src/templates/biomarkerScoreTemplate.tsx" /* webpackChunkName: "component---src-templates-biomarker-score-template-tsx" */),
  "component---src-templates-biomarker-summary-template-tsx": () => import("./../../../src/templates/biomarkerSummaryTemplate.tsx" /* webpackChunkName: "component---src-templates-biomarker-summary-template-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-dynamic-page-tsx": () => import("./../../../src/templates/dynamicPage.tsx" /* webpackChunkName: "component---src-templates-dynamic-page-tsx" */),
  "component---src-templates-partner-tsx": () => import("./../../../src/templates/partner.tsx" /* webpackChunkName: "component---src-templates-partner-tsx" */),
  "component---src-templates-qa-category-tsx": () => import("./../../../src/templates/qaCategory.tsx" /* webpackChunkName: "component---src-templates-qa-category-tsx" */),
  "component---src-templates-vi-2-page-tsx": () => import("./../../../src/templates/vi2Page.tsx" /* webpackChunkName: "component---src-templates-vi-2-page-tsx" */)
}

